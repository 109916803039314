<template>
    <div>
        <b-row>
            <b-col md="12"> 
                <b-card class=" card-congratulations bg-warning match-height" @click="act_data('')">
                    <b-row>
                        <b-col md="4" sm="4" lg="4" xs='4'>
                            <feather-icon icon="ShoppingCartIcon" size="75" />

                        </b-col>
                        <b-col md="8" sm="8" lg="8" xs="3">
                            <blockquote class="blockquote mb-0">
                                <h4 class="text-warning">Pembayaran Aktif <strong class="text-white ml-1">({{ rs_statistik.get_pembayaran }})</strong>
                                </h4> 
                            </blockquote>
                            <small class="text-muted"><i>** Klik unt melihat daftar pembayaran </i></small>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card class=" card-congratulations  bg-danger  match-height"  @click="act_data('histori')">
                    <b-row>
                        <b-col md="3">
                            <feather-icon icon="CheckSquareIcon" size="50" />

                        </b-col>
                        <b-col md="9">
                            <blockquote class="blockquote mb-0">
                                <h4 class="text-warning">Transaksi Pembayaran <strong class="text-white">({{ rs_statistik.get_hostori_pembayaran }})</strong></h4>

                            </blockquote>
                            <small class="text-muted"><i>** Klik unt melihat histori pembayaran </i></small>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BCardText,
        BImg
    } from 'bootstrap-vue'
    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BButton,
            BCard,
            BCardGroup,
            BCardText,
            BImg
        },
        props: {
            rs_statistik: {}
        },
        emits: ['histori'],
        methods: {
            act_data(val){
                this.$emit("histori", val);
            }
        }
    }
</script>

<style>

</style>