<template>
  <div>
    <b-row>
      <b-col md="3">
        <info-tagihan :rs_statistik=rs_statistik @histori=histori></info-tagihan>
      </b-col>
      <b-col md="9">

        <b-card>
          <daftar-tagihan :items=items @actPembayaran=actPembayaran></daftar-tagihan>
        </b-card>
        <!-- <daftar-pembayaraan :items = items></daftar-pembayaraan> -->
      </b-col>
    </b-row>
    <pengajuan :rs_pembayaran=rs_pembayaran :act_pembayaran=act_pembayaran :mhs=mhs :alur=alur @BuatPesanan=BuatPesanan>
    </pengajuan>
    <detail-tagihan :rs_pembayaran=rs_pembayaran :act_pembayaran=act_pembayaran :mhs=mhs :alur=alur></detail-tagihan>
    <konfirmasi-pembayaran :rs_pembayaran=rs_pembayaran :act_pembayaran=act_pembayaran :mhs=mhs :alur=alur
      @konfirmasiPembayaran=konfirmasiPembayaran></konfirmasi-pembayaran>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BAlert,
    BLink,
  } from 'bootstrap-vue'
  import InfoTagihan from './component/infoTagihan.vue'
  import Pengajuan from './component/pengajuan.vue'
  import DaftarPembayaraan from './component/daftarPembayaraan.vue'
  import DetailTagihan from './component/detailTagihan.vue'
  import KonfirmasiPembayaran from './component/konfirmasiPembayaran.vue'
  import daftarTagihan from './component/daftarTagihan.vue'
  import axios from '@/config/Axios'

  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCard,
      BAlert,
      BLink,
      InfoTagihan,
      Pengajuan,
      DaftarPembayaraan,
      DetailTagihan,
      KonfirmasiPembayaran,
      daftarTagihan
    },
    data() {
      return {
        items: [],
        status: [],
        mhs: {},
        totalRows: 0,
        st_konfirmasi: "",
        rs_statistik: {},
        rs_pembayaran: {},
        act_pembayaran: '',
        alur: {},
        log_1: {
          aa: "aa"
        },
        log_2: [],
        log_3: [],
        st_validasi: []
      }
    },
    computed: {},
    mixins: [Base],
    mounted() {
      this.loadData();
      this.statistik();
      this.profil_mahasiswa();
      this.get_alur();
    },
    methods: {
      actPembayaran(rs_pembayaran, act_pembayaran) {
        this.rs_pembayaran = rs_pembayaran
        this.act_pembayaran = act_pembayaran

      },
      async konfirmasiPembayaran(input, file, id) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/mahasiswa/konfirmasi/konformasi_pembayaraan',
            data: {
              id_tansaksi: id,
              terbayar: input.terbayar,
              tgl_pembayaran: input.tgl_pembayaran,
              file: file,
              keterangan: input.keterangan,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "info", response.data.message);
            self.st_konfirmasi = 'konfirmasi';
            self.loadData();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async BuatPesanan(rs_pembayaran) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/mahasiswa/pembayaran/insert',
            data: rs_pembayaran,
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "info", response.data.message);
            self.st_konfirmasi = 'histori';
            self.loadData();


          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      histori(val) {
        this.st_konfirmasi = val;
        this.loadData();
      },
      async profil_mahasiswa() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/mahasiswa/pembayaran/profil_mahasiswa',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.mhs = response.data.result;
            //self.rs_level = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async loadData() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/mahasiswa/pembayaran/load',
            data: {
              st: self.st_konfirmasi
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
            self.totalRows = self.items.length
            self.statistik();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async statistik() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/mahasiswa/pembayaran/statistik',
            data: {
              st: self.st_konfirmasi
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_statistik = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_alur() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/mahasiswa/pembayaran/alur',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.alur = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    },
  }
</script>
<style>
  .bg-card {
    background: #dbdbdb;
  }
</style>