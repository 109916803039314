<template>
    <div>
        <b-modal id="modal-3" title="Detail Tagihan" ok-only ok-title="Accept" size="xl" hide-footer
            no-close-on-backdrop>
            <b-row>
                <b-col md="8">
                    <b-card>
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <h3 class="text-primary invoice-logo">
                                        Fakultas Psikologi
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    Jl. Soekarno-Hatta Tlogosari, Semarang 50196
                                </p>
                                <p class="card-text mb-25">
                                    Gendung H
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-3">
                                <h4 class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">{{ rs_pembayaran.no_inv }}</span>
                                </h4>
                                <div class="mt-md-0 mt-3">
                                    <div class="invoice-date-wrapper">
                                        <p class="invoice-date-title">
                                            Periode Pembayaran <br>{{ rs_pembayaran.tgl_mulai }} -
                                            {{ rs_pembayaran.tgl_selesai }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <h6 class=" ">
                                    Profil
                                </h6>
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25">{{ mhs.nim }}</h5>
                                <small>Nama</small>
                                <h5>{{ mhs.nm_lengkap }}</h5>
                                <small>Mahasiswa kelas <strong>{{ mhs.kelas }}</strong>, tahun angkatan
                                    <strong>{{ mhs.th_angkatan }}</strong></small>
                            </b-col>
                            <b-col md="6">
                                <h6 class=" ">
                                    Informasi Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">Pembayaran</small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_jenis_pembayaran }} - Kelas
                                    {{ rs_pembayaran.kelas }} </h5>
                                <small>Periode Semester </small>
                                <h5 class="text-warning">{{ rs_pembayaran.nm_semester }}</h5>
                                <div>
                                    <small class="mt-5 mt-5">No Rekening</small>
                                    <h5 class=" ">Bank {{ rs_pembayaran.bank }} -
                                        {{ rs_pembayaran.no_rekening }}</h5>
                                    <small>Atasnama : </small>
                                    <h5 class="">{{ rs_pembayaran.atas_nama }}</h5>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6" class="mb-5">
                                <div class="">
                                    <h5>keterangan </h5>
                                    <p v-html="rs_pembayaran.keterangan"> </p>
                                    <h5>ketentuan Jenis pembayaran</h5>
                                    <p v-html="rs_pembayaran.ket_jenis_bayar"> </p>

                                </div>
                            </b-col>
                            <b-col md="6" class="mb-4">
                                <h6 class=" ">
                                    Detail Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">Tagihan </small>
                                <b-row>
                                    <b-col md="2">
                                        <h4><strong>Rp. </strong></h4>
                                    </b-col>
                                    <b-col md="10">
                                        <h1 class="text-warning">
                                            <strong>{{ Rp(rs_pembayaran.tertagih) }},-</strong>
                                        </h1>
                                    </b-col>
                                </b-row>
                                <div v-if="rs_pembayaran.terbayar != null">
                                    <small class="mt-5 mt-5">Terbayar </small>
                                    <b-row>
                                        <b-col md="2">
                                            <h4><strong>Rp. </strong></h4>
                                        </b-col>
                                        <b-col md="10">
                                            <h1 class="text-primary">
                                                <strong>{{ Rp(rs_pembayaran.terbayar) }},-</strong>
                                            </h1>
                                            <small class="mt-5 mt-5">Tanggal Bayar
                                                <strong>{{ rs_pembayaran.tgl_tagihan }}</strong> </small>
                                        </b-col>

                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <img :src="rs_pembayaran.file" width="100%">
                                        </b-col>
                                    </b-row>
                                </div>
                                <div v-if="rs_pembayaran.terbayar == null">
                                    <small>Catatan : </small>
                                    <small>
                                        ** Ketika melakukan pengajuan akan ditambah 2-3 digit unix di nominal
                                        pembayaran,
                                        harap transfer sesuai total tagihan akhir yang tertera di info tagihan
                                    </small>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Ditolak'">
                                    <b-button block variant="danger">Pembayaran Ditolak</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Valid'">
                                    <b-button block variant="primart">Pembayaran Tervalidasi</b-button>
                                </div>
                                <div v-if="rs_pembayaran.status_pembayaran == 'Revisi'">
                                    <b-button block variant="warning">Konfirmasi Pembayaran Direvisi</b-button>
                                </div>

                            </b-col>


                        </b-row>

                    </b-card>
                </b-col>



                <b-col md="4">
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <h4 class="text-primary"> <strong>
                                    <feather-icon icon="CheckSquareIcon" size="25" class="mr-1 " /> Konfirmasi
                                    Pembayaran</strong></h4>
                            <hr>
                            <validation-observer ref="simpleRules">
                                <b-form-group label="Pembayaran Melalui" label-for="nama_bank">
                                    <validation-provider #default="{ errors }" name="nama_bank" rules="required">
                                        <b-form-input v-model="input.jalur_pembayaran"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Nama Bank atau Paymeny getway" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <small>Inputkan nama Bank atau metode paymen yang di gunakan (Ovo, GoPay, Dana,
                                        ect..)</small>
                                </b-form-group>
                                <b-form-group label="Tanggal pembayaran" label-for="tgl_pembayaran">
                                    <validation-provider #default="{ errors }" name="tgl_pembayaran" rules="required">
                                        <b-form-datepicker id="example-datepicker" class="mb-1"
                                            v-model="input.tgl_pembayaran" :state="errors.length > 0 ? false:null"
                                            placeholder="tanggal bayar sesuai di bukti transfer" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small>Masukan tgl bayar sesuai dengan tgl yang tertera di struk pembayaran atau
                                            bukti transfer</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group label="Jumlah Transfer" label-for="basicInput"> 
                                    <validation-provider #default="{ errors }" name="tgl_pembayaran" rules="required">
                                        <currency-input v-model="input.terbayar" class="form-control"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small>Wajib di inputkan sesuai dengan nominal yang di ditransfer ke bank</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group label="Upload Bukti Bayar" label-for="basicInput">
                                    <b-form-file v-model="file" placeholder="Choose a file or drop it here..."
                                        ref="file" id="customFile" @input="uploadFile"
                                        drop-placeholder="Drop file here..." />
                                    <small>Upload bukti bayar atau transfer, pastikan bukti bayar yang di upload bisa
                                        terbaca dengan jelas</small>
                                </b-form-group>
                                <hr>
                                <!-- v-if="fileUpload != ''"  -->
                                <b-button variant="primary" class="mr-2" block @click.prevent="validationForm">
                                    <feather-icon icon="AlignJustifyIcon" size="15" class="mr-1" />Simpan Konfirmasi
                                    Pembayaran
                                </b-button>

                                <b-button variant="warning" block @click="close()">
                                    <feather-icon icon="XIcon" size="15" /> Batal
                                </b-button>
                            </validation-observer>
                        </div>
                    </b-alert>


                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">


                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import CurrencyInput from './CurrencyInput.vue';
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios'
    import {
        useInputImageRenderer
    } from '@core/comp-functions/forms/form-utils'
    import {
        ref
    } from '@vue/composition-api'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BCard,
        BFormDatepicker,
        BFormFile
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BCard,
            BFormDatepicker,
            BFormFile,
            ValidationProvider,
            ValidationObserver,
            CurrencyInput
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                input: {},
                required,
                file: {},
                fileUpload: "",
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        },
        mixins: [Base],
        emits:['konfirmasiPembayaran'],
        props: {
            rs_pembayaran: {},
            act_pembayaran: {},
            mhs: {},
            alur: {},
        },
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        if (confirm('Apakah anda yakin melakuakn pengajuan pembuatan tagihan pembayaran..?') ==
                            true) {
                            this.$emit('konfirmasiPembayaran', this.input, this.fileUpload, this.rs_pembayaran.id_transaksi);
                            this.close();
                            this.input = {}
                        }
                    }

                })
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-3')
            },
            uploadFile() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                const self = this;
                axios.post('api/mahasiswa/konfirmasi/upload_bb', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file = {};
                        self.fileUpload = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
        },
        setup() {
            const refInputEl = ref(null)
            const previewEl = ref(null)

            const {
                inputImageRenderer
            } = useInputImageRenderer(refInputEl, previewEl)

            return {
                refInputEl,
                previewEl,
                inputImageRenderer,
            }
        },
    }
</script>