<template>
    <div>
        <b-modal id="modal-1" title="Pengajuan Pembayaran" ok-only ok-title="Accept" size="xl" hide-footer
            no-close-on-backdrop>
            <b-row>
                <b-col md="8">
                    <b-card>
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper"> 
                                    <h3 class="text-primary invoice-logo">
                                        Fakultas Psikologi
                                    </h3>
                                </div>
                                <p class="card-text mb-25">
                                    Jl. Soekarno-Hatta Tlogosari, Semarang 50196
                                </p>
                                <p class="card-text mb-25">
                                    Gendung H
                                </p>
                                <p class="card-text mb-0">
                                    024 6702272
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-3">
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Periode Pembayaran <br>{{ rs_pembayaran.tgl_mulai }} -
                                        {{ rs_pembayaran.tgl_selesai }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <b-row class="invoice-spacing">
                            <b-col md="6">
                                <h6 class=" ">
                                    Profil
                                </h6>
                                <small class="mb-25">NIM</small>
                                <h5 class="mb-25">{{ mhs.nim }}</h5>
                                <small>Nama</small>
                                <h5>{{ mhs.nm_lengkap }}</h5>
                                <small>Mahasiswa kelas <strong>{{ mhs.kelas }}</strong>, tahun angkatan
                                    <strong>{{ mhs.th_angkatan }}</strong></small>
                            </b-col>
                            <b-col md="6">
                                <h6 class=" ">
                                    Informasi Pembayaran :
                                </h6>
                                <small class="mt-5 mt-5">Pembayaran</small>
                                <h5 class="text-primary">{{ rs_pembayaran.nm_jenis_pembayaran }} - Kelas
                                    {{ rs_pembayaran.kelas }} </h5>
                                <small>Periode Semester </small>
                                <h5 class="text-primary">{{ rs_pembayaran.nm_semester }}</h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6" class="mb-5">
                                <div class="">
                                    <h5>keterangan </h5>
                                    <p v-html="rs_pembayaran.keterangan"> </p>
                                    <h5>ketentuan Jenis pembayaran</h5>
                                    <p v-html="rs_pembayaran.ket_jenis_bayar"> </p>

                                </div>
                            </b-col>
                            <b-col md="6" class="mb-4">
                                <div>
                                    <h6 class=" ">
                                        Detail Pembayaran :
                                    </h6>
                                    <small class="mt-5 mt-5">No Rekening</small>
                                    <h5 class="text-warning">Bank {{ rs_pembayaran.bank }} -
                                        {{ rs_pembayaran.no_rekening }}</h5>
                                    <small>Atasnama : </small>
                                    <h5 class="text-warning">{{ rs_pembayaran.atas_nama }}</h5>
                                </div>
                                <small class="mt-5 mt-5">Total Pembayaran </small>
                                <b-row>
                                    <b-col md="2">
                                        <h4><strong>Rp. </strong></h4>
                                    </b-col>
                                    <b-col md="10">
                                        <h1 class="text-warning">
                                            <strong style="font-size: 55px;">{{ Rp(rs_pembayaran.nominal) }},-</strong>
                                        </h1>
                                    </b-col>
                                </b-row>
                                <small>Catatan : </small>
                                <small>
                                    ** Ketika melakukan pengajuan akan ditambah 2-3 digit unix di nominal pembayaran,
                                    harap transfer sesuai total tagihan akhir yang tertera di info tagihan
                                </small>
                            </b-col>


                        </b-row>

                    </b-card>
                </b-col>


                <b-col md="4">
                    <small class="text-center">Total Ppembayaran </small><br>
                    <b-row>
                        <b-col md="1">
                            <h4><strong>Rp. </strong></h4>
                        </b-col>
                        <b-col md="8">
                            <h1 class="text-primary">
                                <strong style="font-size: 60px;">{{ Rp(rs_pembayaran.nominal) }},-</strong></h1>
                        </b-col>
                    </b-row>


                    <b-button variant="primary" class="mr-2" block @click="BuatPesanan(rs_pembayaran)">
                        <feather-icon icon="AlignJustifyIcon" size="15" /> Buat Tagihan pembayaran
                    </b-button>

                    <b-button variant="warning" block @click="close()">
                        <feather-icon icon="XIcon" size="15" /> Batal
                    </b-button>

                    <b-alert variant="warning" show class="mt-2">
                        <div class="alert-body">
                            <div v-html="alur"></div>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6">


                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BCard,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BCard
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            rs_pembayaran: {},
            act_pembayaran: {},
            mhs: {},
            alur: {}
        },
        data() {
            return {
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        },
        emits: ['BuatPesanan'],
        methods: {
            BuatPesanan(value) {
                if (confirm('Apakah anda yakin melakuakn pengajuan pembuatan tagihan pembayaran..?') == true) {
                    this.$emit('BuatPesanan', value);
                    this.close();
                }
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1')
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
        }
    }
</script>