<template>
    <div>
        <b-row> 
            <b-col md="12">
                <b-row>
                    <b-col>
                        <b-card>
                            <b-form-group label-for="basicInput" style="margin-bottom: -5px;margin-top: -5px;;">
                                <b-form-input id="basicInput" placeholder="Pencarian tagihan aktif " />
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-card>
                    <b-row>
                        <b-col md="4">
                            <h4><small><strong>Pembayaran Skripsi</strong></small> <br> Periode Genap 2022-2023
                            </h4>
                            <b-row>
                                <b-col md="12">
                                    <small>Periode pembayaran </small><br>
                                    <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                        <feather-icon icon="CalendarIcon" size="15" />
                                    </b-avatar>
                                    5 Januari 2022 - 31 Februari 2023
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col md="12">
                                    <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                        <feather-icon icon="AwardIcon" size="15" />
                                    </b-avatar>
                                    <strong>Kelas Pagi</strong>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="5">
                            <div variant="warning" show>
                                <div class="alert-body">

                                    <small>No Rekening</small>
                                    <h6 class="text-warning">Bank BRI 87-098-2098</h6>
                                    <small>Atasnama : </small>
                                    <small><strong> Wahyu Budi Santosa</strong></small>
                                    <small>Periode pembayaran </small><br>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.</p>
                                </div>
                            </div>
                        </b-col>

                        <b-col md="3">
                            <small>Nominal Rp. </small>
                            <h2 class="text-warning text-right item-price"><strong>1.000.000.-</strong></h2>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" v-b-modal.modal-1>
                                <feather-icon icon="ShoppingCartIcon" size="12" /> Ajukan Pembayaran
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card>
                    <b-row>
                        <b-col md="4">
                            <h4><small><strong>Pembayaran Skripsi (Kelas Pagi)</strong></small> <br> Periode Genap 2022-2023
                            </h4>
                            <b-row>
                                <b-col md="12">
                                    <small>Periode pembayaran: </small><br> 
                                    5 Januari 2022 - 31 Februari 2023
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col md="12">
                                    <b-button block variant="gradient-warning"   v-b-modal.modal-2>
                                <feather-icon icon="ClockIcon" size="15" class="mr-1" />Menunggu Proses Konfirmasi
                            </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="5">
                            <div variant="warning" show>
                                <div class="alert-body">

                                    <small>No Rekening</small>
                                    <h6 class="text-warning">Bank BRI 87-098-2098</h6>
                                    <small>Atasnama : </small>
                                    <small><strong> Wahyu Budi Santosa</strong></small>
                                    <small>Periode pembayaran </small><br>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.</p>
                                </div>
                            </div> 
                        </b-col>

                        <b-col md="3"> 
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="gradient-primary"  v-b-modal.modal-2>
                                <feather-icon icon="LayersIcon" size="15" class="mr-1" /> Detail Tagihan
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="gradient-warning" v-b-modal.modal-3>
                                <feather-icon icon="SendIcon" size="15" /> Konfirmasi Pembayaran
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>

                <b-row>
                    <b-col md="6">Total Row <strong>{{ totalRows }}</strong></b-col>
                    <b-col md="6">
                        <b-pagination v-model="currentPage" :total-rows="rows" base-url="#" align ="right" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row> 
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BLink,
    } from 'bootstrap-vue' 

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            BAlert,
            BLink, 
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'deskripsi',
                    label: '',
                }],
                items: [{
                        id: 1,

                        full_name: "Korrie O'Crevy",
                        keterangan: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

                    },
                    {
                        id: 2,
                        full_name: 'Bailie Coulman',
                        keterangan: 'pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

                    },
                ],
                status: [],
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .bg-card {
        background: #dbdbdb;
    }
</style>